import React from 'react';
export const getFormattedText = (text, replacements = {}) => {
  return text.split(/\r?\n/).map((line, i) => {
    return (
      <div key={i}>
        {line.split(' ').map((word, j) => {
          const replacement = replacements[word] && replacements[word](word);
          return <span key={j}>{replacement || word} </span>;
        })}
      </div>
    );
  });
};
