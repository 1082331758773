import React from 'react';
import styled from 'styled-components';
const StyledButton = styled.button`
  height: 36px;
  padding: 16px 36px;
  background: #27609b;
  color: white;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
`;
const Button = ({ uri, label }) => {
  return (
    <StyledButton
      onClick={() => {
        window.open(uri, '_blank');
        //window.location = uri;
      }}
    >
      {label}
    </StyledButton>
  );
};
export default Button;
