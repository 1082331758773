import React, { useEffect } from 'react';
import { appApi } from 'services/AppService';
import { useAppStore } from 'services/AppService/hooks';
import Routes from '../Routes';

export default function App() {
  const jwt = useAppStore(state => state.jwt);
  useEffect(() => {
    appApi.getState().init();
  }, [jwt]);
  return <Routes />;
}
