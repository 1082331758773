import React, { useEffect, useState } from 'react';
import { useScrollYPosition } from 'react-use-scroll-position';
import styled from 'styled-components';
import { mapNewArray } from '../../utils/array';
const IMAGE_COUNT = 250;
const StyledImage = styled.img`
  position: absolute;
  top: -300px;
  left: 0;
  visibility: ${props => (props.visible ? 'initial' : 'hidden')};
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const StyledContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
`;
const useScrollY = () => {
  const [posY, setPosY] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setPosY(window.pageYOffset);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return posY;
};
const RenderBanner = () => {
  const reduction = 8;
  const indices = mapNewArray(Math.floor(IMAGE_COUNT / reduction), i => i * reduction);
  return (
    <StyledContainer>
      {indices.map(i => {
        const scrollY = useScrollY();
        const normalized = Math.max(0.0, Math.min(1.0, (1.0 * scrollY + 400) / 1000.0));
        const isActive = i === indices[Math.floor(indices.length * normalized)];
        return (
          <StyledImage
            visible={isActive}
            key={i}
            src={`/assets/${String(i + 1).padStart(4, '0')}.png.webp`}
          />
        );
      })}
    </StyledContainer>
  );
};
export default RenderBanner;
