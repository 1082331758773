// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Roboto-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Roboto-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/Roboto-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: monospace;\n}\n\n@font-face {\n  font-family: 'Roboto';\n  font-weight: 400;\n  src: local('Roboto'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Roboto';\n    font-weight: 500;\n    src: local('Roboto'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Roboto';\n    font-weight: 700;\n    src: local('Roboto'), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,uBAAuB;EACvB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,gFAAwE;AAC1E;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gFAAuE;AAC3E;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,gFAAqE;AACzE","sourcesContent":["body {\n  margin: 0;\n  font-family: sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: monospace;\n}\n\n@font-face {\n  font-family: 'Roboto';\n  font-weight: 400;\n  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');\n}\n\n@font-face {\n    font-family: 'Roboto';\n    font-weight: 500;\n    src: local('Roboto'), url(./fonts/Roboto-Medium.ttf) format('truetype');\n}\n\n@font-face {\n    font-family: 'Roboto';\n    font-weight: 700;\n    src: local('Roboto'), url(./fonts/Roboto-Bold.ttf) format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
