import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const GRID = {
  COLUMN: 140,
  SPACE: 20,
};
export const THREE_COLUMNS = GRID.COLUMN * 3 + GRID.SPACE * 2;
export const SIX_COLUMNS = GRID.COLUMN * 6 + GRID.SPACE * 5;
export const BREAKPOINT = {
  M: 540,
  L: 1260,
};

export const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const appear = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
export const easing = 'cubic-bezier(0.010, 0.990, 0.320, 1.000)';
export const mediaMin = (width, styles) => css`
  @media (min-width: ${width}px) {
    ${styles}
  }
`;

export const Container = styled.div`
  margin-left: ${props => props.left || 0}px;
  margin-right: ${props => props.right || 0}px;
  margin-top: ${props => props.top || 0}px;
  margin-bottom: ${props => props.bottom || 0}px;
`;
export const Title = styled.div`
  margin: 0;
  white-space: normal;
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
`;
export const Headline = styled.div`
  margin: 0;
  white-space: normal;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
`;
export const Text = styled.div`
  margin: 0;
  white-space: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;
export const Key = styled.div`
  display: inline;
  margin: 0;
  white-space: normal;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
`;
export const Value = styled.div`
  display: inline;
  width: 170px;
  white-space: normal;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
`;
export const Center = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;
export const KeyValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaMin(
    BREAKPOINT.L,
    css`
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    `,
  )}
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  max-width: ${THREE_COLUMNS}px;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  max-width: ${THREE_COLUMNS}px;
  ${mediaMin(
    BREAKPOINT.L,
    css`
      margin-left: 160px;
    `,
  )}
`;

export const TransitionContainer = styled.div`
  opacity: 0;
  animation: ${appear} ${props => props.duration || 2}s ${easing} forwards;
  animation-delay: ${props => props.index * (props.delay || 0.1)}s;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s ${easing};
  max-width: 500px;
  ${mediaMin(
    BREAKPOINT.M,
    css`
      width: ${THREE_COLUMNS}px;
    `,
  )}
  ${mediaMin(
    BREAKPOINT.L,
    css`
      width: 100%;
      max-width: initial;
    `,
  )}
`;

export const MainContainer = styled.div`
  opacity: 0;
  animation: ${fade} 0.5s linear forwards;
  // display: flex;
  // align-items: center;
  // width: 100%;
`;
export const StyledLink = styled(Link)`
  color: black;
`;
export const StyledAnchor = styled.a`
  color: black;
`;
