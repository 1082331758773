import React, { useEffect } from 'react';
import Page from 'components/Page';
import { useAppStore } from 'services/AppService/hooks';
import { useHistory, useParams } from 'react-router';
import { MainContainer } from '../../common/styles';
import Login from '../Login';
import RenderBanner from '../RenderBanner';
import Header from '../Header';

export default function Pages() {
  const { selectedPage = 'home' } = useParams();
  const isMenu = useAppStore(state => state.isMenu);
  const page = useAppStore(state => state.pages[selectedPage]);
  //const home = useAppStore(state => state.pages['home']);
  const isLoaded = useAppStore(state => state.loaded);
  const history = useHistory();
  const jwt = useAppStore(state => state.jwt);
  const hasAccess = jwt || page?.public;
  useEffect(() => {
    if (!page || (!page.public && !jwt)) {
      return;
    }
    if (isLoaded && selectedPage !== '' && !page) {
      history.push('/');
    }
    if (page.meta.title && page.meta.title !== '') {
      document.title = page.meta.title;
    }
  }, [isLoaded, page, selectedPage, jwt]);
  const hasBanner = page && page.meta.banner;
  const hasHeader = page && page.meta.header;
  return (
    <>
      {hasBanner && <RenderBanner />}
      {hasHeader && <Header />}
      {isLoaded && (
        <MainContainer>
          {!isMenu && (
            <>
              {!hasAccess && <Login />}
              {hasBanner && <div style={{ height: '60vh' }} />}
              {hasAccess && page && <Page page={page} />}
            </>
          )}
          {/* {isMenu && <Menu />} */}
        </MainContainer>
      )}
    </>
  );
}
