import React, { useState } from 'react';
import styled from 'styled-components';
import { appApi } from 'services/AppService';
import { MainContainer } from 'common/styles';
import Lock from './assets/lock.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
`;

const StyledInput = styled.input`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 3px solid #f6f6f6;
  box-sizing: border-box;
  border-radius: 32px;
  height: 48px;
  width: 256px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  outline: none;
`;

const StyledSubmit = styled.input`
  display: none;
`;

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = e => {
    appApi.getState().login({ email, password });
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Lock />
        <StyledInput
          placeholder={'email'}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <StyledInput
          type={'password'}
          placeholder={'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <StyledSubmit type="submit" value=">" />
      </Container>
    </form>
  );
}
