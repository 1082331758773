import React from 'react';
import { Canvas } from 'react-three-fiber';
export default function Three() {
  return (
    <Canvas
      style={{
        height: '400px',
      }}
    >
      <mesh>
        <meshStandardMaterial></meshStandardMaterial>
        <boxBufferGeometry></boxBufferGeometry>
      </mesh>
    </Canvas>
  );
}
