import create from 'zustand/vanilla';
import { getJSON, postJSON } from 'utils/api';
const STRAPI = {
  production: 'https://strapi.bemersem.uber.space',
  local: 'http://localhost:1337',
};
export const getStrapiUrl = () => {
  const isLocal = false && window.location.href.startsWith('http://localhost');
  return isLocal ? STRAPI.local : STRAPI.production;
};

export const appApi = create(set => ({
  isMenu: false,
  tags: [],
  selectedTags: [],
  categories: [],
  pages: {},
  loaded: false,
  jwt: null,
  init: async () => {
    const strapiUrl = getStrapiUrl();
    const jwt = localStorage.getItem('jwt') || appApi.getState().jwt;
    const appData = await getJSON(`${strapiUrl}/pages/get`, jwt);
    const pages = appData.pages.reduce((acc, a) => {
      acc[a.name] = a;
      return acc;
    }, {});
    set({ pages, info: appData.info, loaded: true });
  },
  login: async ({ email, password }) => {
    const strapiUrl = getStrapiUrl();
    const credentials = {
      identifier: email,
      password,
    };
    try {
      const data = await postJSON(`${strapiUrl}/auth/local`, credentials);
      const { user, jwt } = data;
      if (user && jwt) {
        localStorage.setItem('jwt', jwt);
        set({ user, jwt });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  },
}));
