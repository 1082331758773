import React from 'react';
import styled, { css } from 'styled-components';
import { BREAKPOINT, GRID, mediaMin } from '../../common/styles';
const Container = styled.div`
  //background: #9e978e;
  background: rgb(245, 227, 204);
  background: linear-gradient(225deg, rgba(245, 227, 204, 1) 0%, rgba(201, 226, 224, 1) 100%);
  display: flex;
  align-items: flex-end;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  ${mediaMin(
    BREAKPOINT.L,
    css`
      height: 160px;
      padding-left: 0px;
      padding-top: 0px;
      justify-content: center;
    `,
  )}
`;
const HeadlineContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
  width: 100%;
  ${mediaMin(
    BREAKPOINT.L,
    css`
      max-width: ${GRID.COLUMN * 7 + GRID.SPACE * 6}px;
      justify-content: flex-start;
    `,
  )}
`;
const Headline = styled.div`
  display: inline;
  font-weight: 900;
  font-size: 36px;
  line-height: 28px;
  color: ${props => props.color};
`;

export default function Header() {
  return (
    <Container>
      <HeadlineContainer>
        <Headline color={'black'}>sambmr</Headline>
        <Headline color={'white'}>.com</Headline>
      </HeadlineContainer>
    </Container>
  );
}
