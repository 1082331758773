import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}
`;

const root = document.createElement('div');
root.className = 'application';
document.body.appendChild(root);
ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  root,
);
