import React from 'react';
import {
  Center,
  Container,
  Headline,
  Key,
  KeyValue,
  LeftColumn,
  Text,
  Title,
  Value,
} from 'common/styles';
import { getFormattedText } from 'utils/text';
import styled from 'styled-components';
import {
  Columns,
  RightColumn,
  StyledAnchor,
  StyledLink,
  TransitionContainer,
} from '../../common/styles';
import Button from '../Button';
import AssetImage from '../AssetImage';

const Replaced = styled.span`
  ${props => props.bold && 'font-weight: 700;'}
  ${props => props.color && `color: ${props.color}`}
`;

function getReplaced(text, replacement) {
  const rs = replacement.reduce((acc, a) => {
    // eslint-disable-next-line react/display-name
    acc[a.key] = () => {
      return (
        <Replaced bold={a.bold} color={a.color}>
          {a.value}
        </Replaced>
      );
    };
    return acc;
  }, {});
  return getFormattedText(text, rs);
}

function getPage(page) {
  const jwt = localStorage.getItem('jwt');
  return (
    <LeftColumn>
      {page.content.map((a, i) => {
        if (a.hidden) {
          return null;
        }
        const type = a.__component;
        switch (type) {
          case 'misc.interactive': {
            switch (a.type) {
              case 'logo': {
                return null;
                // return <div key={i} style={{ height: 500 }}></div>;
              }
              case 'button': {
                return (
                  <TransitionContainer key={i} index={i}>
                    <Container top={0} bottom={24}>
                      <Button label={a.label} uri={a.uri} />
                    </Container>
                  </TransitionContainer>
                );
              }
              default: {
                return null;
              }
            }
          }
          case 'typo.title': {
            const t = getReplaced(a.text, a.replacement);
            return (
              <TransitionContainer key={i} index={i}>
                <Title key={i}>{t}</Title>
              </TransitionContainer>
            );
          }
          case 'typo.headline': {
            const t = getReplaced(a.text, a.replacement);
            return (
              <TransitionContainer key={i} index={i}>
                <Center>
                  <Headline>{t}</Headline>
                </Center>
              </TransitionContainer>
            );
          }
          case 'typo.key-value': {
            const { key, value } = a;
            return (
              <TransitionContainer key={i} index={i}>
                <KeyValue key={i}>
                  <Key>{key}:</Key>
                  <Value>{value}</Value>
                </KeyValue>
              </TransitionContainer>
            );
          }
          case 'typo.paragraph': {
            const t = getReplaced(a.text, a.replacement);
            return (
              <TransitionContainer key={i} index={i}>
                <Container top={0} bottom={24}>
                  <Text>{t}</Text>
                </Container>
              </TransitionContainer>
            );
          }
          case 'relation.link': {
            const t = getReplaced(a.text, a.replacement);
            const active = a.public || jwt;
            const external = a.link.startsWith('http');
            return (
              <TransitionContainer key={i} index={i}>
                <Container top={0} bottom={24}>
                  {active && !external && (
                    <StyledLink to={a.link}>
                      <Text>{t}</Text>
                    </StyledLink>
                  )}
                  {active && external && (
                    <StyledAnchor target="_blank" href={a.link}>
                      <Text>{t}</Text>
                    </StyledAnchor>
                  )}
                  {!active && <Text styles={{ color: 'grey', fontFamily: 'bold' }}>{t}</Text>}
                </Container>
              </TransitionContainer>
            );
          }
          case 'asset.image': {
            return (
              <TransitionContainer key={i} index={i}>
                <Container bottom={20}>
                  {a.media.map((b, j) => (
                    <AssetImage url={b.url} key={j} />
                  ))}
                </Container>
              </TransitionContainer>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </LeftColumn>
  );
}

const getTeasers = page => (
  <RightColumn>
    {page.meta.teaser &&
      page.meta.teaser.map((teaser, i) => (
        <Container bottom={20} key={i}>
          {teaser.media.map((b, j) => (
            <AssetImage url={b.url} key={j} />
          ))}
        </Container>
      ))}
  </RightColumn>
);

export default function Page({ page }) {
  return (
    <Columns>
      {getPage(page)}
      {getTeasers(page)}
    </Columns>
  );
}
