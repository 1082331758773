import { Route, BrowserRouter, Switch, useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import Pages from '../Pages';
import Login from '../Login';
import { appApi } from '../../services/AppService';
import Three from '../Three';

function Logout() {
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('jwt');
    appApi.setState({ jwt: null });
    history.push('/');
  }, []);
  return null;
}

export default function Routes() {
  const jwt = localStorage.getItem('jwt');
  return (
    <BrowserRouter>
      <Switch>
        {/* {!jwt && <Route key="login" path="/login" component={Login} />} */}
        {jwt && <Route key="logout" path="/logout" component={Logout} />}
        <Route key="three" path="/three" component={Three} />
        <Route key="page" path="/:selectedPage" component={Pages} />
        <Route key="home" path="/" component={Pages} />
      </Switch>
    </BrowserRouter>
  );
}
