export const getJSON = async (url, jwt) => {
  const headers = {};
  if (jwt) {
    headers['Authorization'] = `Bearer ${jwt}`;
  }
  const res = await fetch(url, {
    headers,
  });
  return await res.json();
};

export const postJSON = async (url, data, jwt) => {
  const body = JSON.stringify(data);
  const headers = {
    'Content-Type': 'application/json',
  };
  if (jwt) {
    headers['Authorization'] = `Bearer ${jwt}`;
  }
  const res = await fetch(url, {
    method: 'POST',
    body,
    headers,
  });
  return await res.json();
};
